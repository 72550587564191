import React from "react";
import { useTheme } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "../common/Card";
import { useParams } from "react-router-dom";
import { useLoader } from "../customHooks/useLoader";
import { httpGet } from "../../services/http/httpService";
import InfoBox from "../common/InfoBox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { feilmeldinger } from "../../utils/constants";
import TilbakeButton from "../common/TilbakeButton";
import Background from "../common/Background";
import Kontaktliste from "./KontaktListe";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PrestIBeredskap from "../beredskapsomrade/prestIBeredskap";
import PresterIBeredskapsomradetMobil from "./PresterIBeredskapsomradetMobil";
import BeredskapsomradeMedKommunerTittel from "./BeredskapsomradeMedKommunerTittel";

interface ParamTypes {
    beredskapsomrade_id: string;
}

const PresterIBeredskapsomradet: React.FC = () => {
    const theme = useTheme();
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const { beredskapsomrade_id } = useParams<ParamTypes>();
    const { data, loading, error } = useLoader(() =>
        httpGet(`api/Beredskapsomrader/${beredskapsomrade_id}`)
    );
    const hentTreFørsteIBeredskap = (personer: any[]) => {
        const sortedPersoner = [...personer].sort((a, b) =>
            a.navn.localeCompare(b.navn)
        );
        return sortedPersoner.filter((x) => x.iBeredskap).slice(0, 2);
    };

    return (
        <Background>
            <Card padding="1.5rem">
                <TilbakeButton linkTo={"/prest"} />
                {loading && <LinearProgress />}
                {error && (
                    <InfoBox
                        message={feilmeldinger.uventetFeil}
                        icon={<ErrorOutlineIcon />}
                    />
                )}
                {!isOnMobileDevice && data && (
                    <>
                        <BeredskapsomradeMedKommunerTittel
                            beredskapsomrade={data.beredskapsomrade}
                            kommuner={data.beredskapsomrade.kommuner}
                        />
                        {hentTreFørsteIBeredskap(data.prester)?.map(
                            (prest, index) => (
                                <div key={index}>
                                    <PrestIBeredskap prest={prest} />
                                </div>
                            )
                        )}
                        <Kontaktliste
                            kontaktpersoner={data.prester}
                            tittel="Prest"
                        />
                    </>
                )}
                {isOnMobileDevice && data && (
                    <PresterIBeredskapsomradetMobil
                        prester={data.prester}
                        beredskapsomrade={data.beredskapsomrade}
                    />
                )}
            </Card>
        </Background>
    );
};
export default PresterIBeredskapsomradet;
