import React from "react";
import ListMUI from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Kontaktinfo } from "../../store/prester/types";
import TelephoneNumber from "../common/TelephoneNumber";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Container } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: "2rem",
        marginBottom: "0.5rem",
        marginLeft: "0.5rem",
        width: "50%",
        fontWeight: "bold",
    },
    info: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        marginLeft: "0.5rem",
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    flexstyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    colortext: {
        color: "black",
        marginLeft: "1rem",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0",
        },
    },
    successIcon: {
        marginRight: "0.2rem",
        fill: "#06893A",
        marginBottom: "0.1rem",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            marginLeft: "auto",
            marginRight: "0.5rem",
            marginBottom: "1.5rem",
            flexDirection: "row",
            alignItems: "space-between",
            justifyContent: "space-between",
        },
        display: "inline-flex",
    },
    successContent: {
        marginLeft: 0,
        fill: "#06893A",
        display: "flex",
        borderRadius: "0.25rem",
        maxWidth: 280,
        padding: "0.5rem",
    },
    chip: {
        background: "#06893A",
        color: theme.palette.common.white,
        marginLeft: "-0.5rem",
    },
    invchip: {
        background: "white",
        color: theme.palette.common.white,
    },
    container: {
        display: "inline-flex",
    },
    telefonnr: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        marginLeft: "0.5rem",
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
        },
    },
}));

interface Props {
    kontaktpersoner: Kontaktinfo[];
    tittel: string;
}

const Kontaktliste: React.FC<Props> = ({ kontaktpersoner, tittel }) => {
    const classes = useStyles();
    const theme = useTheme();
    const erPrest = tittel === "Prest";
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const sortedKontakter = [...kontaktpersoner].sort((a, b) =>
        a.navn.localeCompare(b.navn)
    );
    const getNumber = (number: string) => "tel:+47" + number;

    return (
        <ListMUI>
            {isOnMobileDevice && (
                <>
                    <ListItem disableGutters divider>
                        <Typography className={classes.header} variant={"h6"}>
                            {tittel}
                        </Typography>
                    </ListItem>
                    {sortedKontakter?.map((kontaktperson, i) => (
                        <ListItem
                            className={classes.flexstyle}
                            divider
                            disableGutters
                            key={i}
                        >
                            <Typography className={classes.info} variant={"h6"}>
                                {kontaktperson.navn}
                            </Typography>
                            <Typography
                                className={classes.telefonnr}
                                variant={"h6"}
                            >
                                <a
                                    href={getNumber(
                                        kontaktperson.telefonnummer
                                    )}
                                    className={classes.colortext}
                                >
                                    {kontaktperson.telefonnummer}
                                </a>
                                {kontaktperson.iBeredskap && (
                                    <CheckCircleIcon
                                        className={classes.successIcon}
                                    />
                                )}
                            </Typography>
                        </ListItem>
                    ))}
                </>
            )}

            {!isOnMobileDevice && (
                <>
                    <ListItem disableGutters divider>
                        <Typography className={classes.header} variant={"h6"}>
                            {tittel}
                        </Typography>
                        <Typography className={classes.header} variant={"h6"}>
                            Telefonnummer
                        </Typography>
                        {erPrest && (
                            <Typography
                                className={classes.header}
                                variant={"h6"}
                            >
                                I beredskap
                            </Typography>
                        )}
                    </ListItem>
                    {sortedKontakter?.map((kontaktperson, i) => (
                        <ListItem disableGutters divider key={i}>
                            <Typography className={classes.info} variant={"h6"}>
                                {kontaktperson.navn}
                            </Typography>
                            <Typography className={classes.info} variant={"h6"}>
                                <TelephoneNumber
                                    telephonenumber={
                                        kontaktperson.telefonnummer
                                    }
                                />
                            </Typography>
                            {erPrest && (
                                <Typography
                                    className={classes.info}
                                    variant={"h6"}
                                >
                                    <Container
                                        className={classes.successContent}
                                    >
                                        {kontaktperson.iBeredskap ? (
                                            <Chip
                                                label="I BEREDSKAP"
                                                className={classes.chip}
                                            />
                                        ) : (
                                            <Chip
                                                label="IKKE BEREDSKAP"
                                                className={classes.invchip}
                                            />
                                        )}
                                    </Container>
                                </Typography>
                            )}
                        </ListItem>
                    ))}
                </>
            )}
        </ListMUI>
    );
};
export default Kontaktliste;
