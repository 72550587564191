import React from "react";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    iconText: {
        marginLeft: "0.2rem",
        marginRight: "0.2rem",
    },
}));

interface Props {
    text: string;
    ariaLabel: string;
    iconStyle: string;
    containerStyle: string;
}

const OmradeStatus: React.FC<Props> = ({ text, ariaLabel, iconStyle, containerStyle }) => {
    const classes = useStyles();

    const theme = useTheme();
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    return (
        <>
            {isOnMobileDevice ? (
                <ErrorIcon className={iconStyle} aria-label={ariaLabel} />
            ) : (
                <Container className={containerStyle}>
                    <ErrorIcon className={iconStyle} aria-label={ariaLabel} />
                    <Typography
                        variant="subtitle1"
                        className={classes.iconText}
                    >
                        {text}
                    </Typography>
                </Container>
            )}
        </>
    );
};

export default OmradeStatus;
