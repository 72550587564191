import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "../common/Card";
import { useParams } from "react-router-dom";
import { useLoader } from "../customHooks/useLoader";
import { httpGet } from "../../services/http/httpService";
import InfoBox from "../common/InfoBox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { feilmeldinger } from "../../utils/constants";
import TilbakeButton from "../common/TilbakeButton";
import Background from "../common/Background";
import Kontaktliste from "../prester/KontaktListe";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BeredskapsomradeMedKommunerTittel from "../prester/BeredskapsomradeMedKommunerTittel";

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        marginBottom: "1rem",
    },
    beredans: {
        marginTop: "5rem",
    },
    beredansMobile: {
        marginTop: "2rem",
        fontWeight: "bold",
    },
}));

interface ParamTypes {
    beredskapsomrade_id: string;
}

export enum ROLLE {
    prost = 2,
    beredskapsansvarlig = 3,
}

const KontaktpersonerIBeredskapsomradet: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isOnMobileDevice = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

    const { beredskapsomrade_id } = useParams<ParamTypes>();
    const { data, loading, error } = useLoader(() =>
        httpGet(`api/Beredskapsomrader/${beredskapsomrade_id}/kontaktpersoner`)
    );

    const beredansListe = (response: any) =>
        response.kontaktpersoner.filter(
            (e: any) => e.roleId === ROLLE.beredskapsansvarlig
        );
    const prosteListe = (response: any) =>
        response.kontaktpersoner.filter((e: any) => e.roleId === ROLLE.prost);

    return (
        <Background>
            <Card padding="1.5rem">
                <TilbakeButton linkTo={"/prest"} />
                {loading && <LinearProgress />}
                {error && (
                    <InfoBox
                        message={feilmeldinger.uventetFeil}
                        icon={<ErrorOutlineIcon />}
                    />
                )}

                {!isOnMobileDevice && data && (
                    <>
                        <BeredskapsomradeMedKommunerTittel
                            beredskapsomrade={data.beredskapsomrade}
                            kommuner={data.beredskapsomrade.kommuner}
                        />
                        <Kontaktliste
                            kontaktpersoner={prosteListe(data)}
                            tittel="Prost og prostesaksbehandler"
                        />
                        <Kontaktliste
                            kontaktpersoner={beredansListe(data)}
                            tittel="Beredskapsansvarlig"
                        />
                    </>
                )}
                {isOnMobileDevice && data && (
                    <>
                        <Typography variant="h4" className={classes.mainTitle}>
                            {data.beredskapsomrade.navn}
                        </Typography>
                        <Kontaktliste
                            kontaktpersoner={prosteListe(data)}
                            tittel="Prost og prostesaksbehandler:"
                        />

                        <Kontaktliste
                            kontaktpersoner={beredansListe(data)}
                            tittel="Beredskapsansvarlig:"
                        />
                    </>
                )}
            </Card>
        </Background>
    );
};
export default KontaktpersonerIBeredskapsomradet;
