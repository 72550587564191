import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import SyncRoundedIcon from "@material-ui/icons/SyncRounded";
import { isIOsInStandaloneMode } from "../../utils/isIOsInStandaloneMode";
import Drawer from "../common/Drawer";
import Settings from "../settings/Settings";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    logo: {
        cursor: "pointer",
        paddingLeft: "1rem",
    },
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
}));

const Header: React.FC = () => {
    const classes = useStyles();

    const refreshPage = () => {
        window.location.assign(window.location.pathname);
    };
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });
    const history = useHistory();

    const navigateHome = () => {
        history.push("/");
    };

    const Logo: React.FC = () => {
        if (isOnMobile || isIOsInStandaloneMode()) {
            return <></>;
        }
        return (
            <img
                className={classes.logo}
                src="/images/logo-kirken.svg"
                alt="kirke logo"
                width="180px"
                height="auto"
                onClick={navigateHome}
            />
        );
    };

    return (
        <AppBar
            elevation={0}
            position="static"
            color="inherit"
            className={classes.root}
        >
            <Container>
                <Toolbar className={classes.wrapper}>
                    <Logo />
                    {isIOsInStandaloneMode() && (
                        <Button
                            endIcon={<SyncRoundedIcon />}
                            onClick={refreshPage}
                        >
                            Oppdater status
                        </Button>
                    )}
                    <Drawer>
                        <Settings />
                    </Drawer>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;
