import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme: Theme) => ({
    imageWrapper: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: "2rem",
            paddingLeft: "2rem",
            paddingBottom: "2rem",
        },
        paddingTop: "1rem",
        paddingLeft: "2rem",
    },
}));

const KirkeLogo: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down("sm"), { noSsr: true });

    return (
        <div className={classes.imageWrapper}>
            <img
                src="/images/logo-kirken.svg"
                alt="kirke logo"
                width={isOnMobile ? "70%" : "60%"}
                height="auto"
            />
        </div>
    );
};
export default KirkeLogo;
